import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { openSnackbar } from '../../store/actions';
import { fetchChains } from '../../services/carrierDactylService';
import { snackBarVariant } from '../../types/snackBar';
import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme) => ({
  formField: {
    position: 'relative',
    width: '328px',
    margin: '18px 0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 45px)',
      margin: '18px 22.5px',
    },
  },
}));

const MIN_FILTER_TEXT_LENGTH = 3;

export default function ChainAutocomplete({ onChainChange }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [chainFilterDebouncedText, setChainFilterDebouncedText] =
    useState(null);
  const [chainOptions, setChainOptions] = useState([]);
  const [chainOptionsLoading, setChainOptionsLoading] = useState(false);
  const [chainOptionsLoadingText, setChainOptionsLoadingText] = useState(
    'Type to See Options'
  );

  // this is what drives, debounced, us looking up new chains server side
  // this must be different from the text that is actually in the input box
  // because when a user selects a chain from the list, we want to change the text in the input box
  // to that chain's full name, without performing another server-side lookup
  const [chainFilterLatestText, setChainFilterLatestText] = useState('');

  // we control the state of the selected chain and the text in the input box separately
  const [selectedChain, setSelectedChain] = useState(null);
  const [inputText, setInputText] = useState('');

  const setChainFilterDebouncedTextDebounced = useRef(
    debounce(setChainFilterDebouncedText, 500)
  ).current;

  const handleChainChange = (_e, value) => {
    setInputText(value ? value.name : '');
    setSelectedChain(value);
    onChainChange(value);
  };

  useEffect(() => {
    const getChainOptions = async (filter) => {
      setChainOptionsLoading(true);

      // clear selected chain state as the list of available chains is about to change
      setSelectedChain(null);
      setInputText(filter);

      const chainsResponse = await fetchChains(10, filter);
      if (chainsResponse.success) {
        setChainOptions(chainsResponse.data);
      } else {
        dispatch(
          openSnackbar(
            `Failed to get Chains. ${chainsResponse.errorMessage}.`,
            snackBarVariant.error
          )
        );
      }
      setChainOptionsLoading(false);
    };

    if (
      chainFilterDebouncedText &&
      chainFilterDebouncedText.length >= MIN_FILTER_TEXT_LENGTH
    )
      getChainOptions(chainFilterDebouncedText);
  }, [dispatch, chainFilterDebouncedText]);

  useEffect(() => {
    if (chainOptionsLoading) {
      setChainOptionsLoadingText('Loading');
      return;
    }
    if (
      !chainFilterLatestText ||
      chainFilterLatestText.length < MIN_FILTER_TEXT_LENGTH
    ) {
      setChainOptionsLoadingText('Type More to See Chains');
      return;
    }
    setChainOptionsLoadingText('No Matching Chains');
  }, [chainOptionsLoading, chainOptions, chainFilterLatestText]);

  const handleChainFilterChange = (e) => {
    const value = e.target.value;

    // tell the parent component that chain is now null as user is typing to look for new options
    onChainChange(null);

    setChainOptions([]);
    setInputText(value);
    setChainFilterLatestText(value);
    setChainFilterDebouncedTextDebounced(value);
  };

  return (
    <Autocomplete
      data-testid="chain-texfield"
      inputValue={inputText}
      value={selectedChain}
      disabled={chainOptionsLoading}
      className={classes.formField}
      options={chainOptions}
      getOptionLabel={(chain) => chain.name}
      onChange={handleChainChange}
      loading={!chainOptions || chainOptions.length === 0}
      loadingText={chainOptionsLoadingText}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Chain"
          variant="outlined"
          helperText={
            chainOptions.length === 0 ? 'Start Typing to Get Options' : null
          }
          onChange={handleChainFilterChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {chainOptionsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
