import React, { Component, createContext, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { loadApp } from '../store/actions';
import { connect } from 'react-redux';
import { getEnvVarByName } from '../utils';
import { setGetTokenSilent } from '../services/carrierDactylService';

const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

const getAuth0Domain = () => {
  let domain = getEnvVarByName('REACT_APP_AUTH0_DOMAIN').toLowerCase();

  if (domain.indexOf('https://') >= 0) domain = domain.replace('https://', '');

  if (domain.indexOf('/') >= 0) domain = domain.replace('/', '');

  return domain;
};

class Auth0ProviderDisconnected extends Component {
  state = {
    auth0Client: null,
    isLoading: true,
    isAuthenticated: false,
    user: null,
  };
  config = {
    domain: getAuth0Domain(),
    client_id: getEnvVarByName('REACT_APP_AUTH0_CLIENT_ID'),
    redirect_uri: window.location.origin,
    audience: getEnvVarByName('REACT_APP_AUTH0_AUDIENCE'),
  };

  componentDidMount() {
    this.initializeAuth0();
  }

  initializeAuth0 = async () => {
    const auth0Client = await createAuth0Client(this.config);
    this.setState({ auth0Client });
    if (window.location.search.includes('code=')) {
      return this.handleRedirectCallback();
    }
    const isAuthenticated = await auth0Client.isAuthenticated();
    const user = isAuthenticated ? await auth0Client.getUser() : null;
    this.setState({ isLoading: false, isAuthenticated, user });
    if (isAuthenticated) this.props.loadApp();
  };

  handleRedirectCallback = async () => {
    this.setState({ isLoading: true });
    await this.state.auth0Client.handleRedirectCallback();
    const user = await this.state.auth0Client.getUser();
    this.setState({ user, isAuthenticated: true, isLoading: false });
    window.history.replaceState({}, document.title, window.location.pathname);
    this.props.loadApp();
  };

  render() {
    const { auth0Client, isLoading, isAuthenticated, user } = this.state;
    const { children } = this.props;
    setGetTokenSilent((...p) => auth0Client.getTokenSilently(...p));

    return (
      <Auth0Context.Provider
        value={{
          isLoading,
          isAuthenticated,
          user,
          loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
          getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          logout: (...p) => {
            auth0Client.logout(...p);
          },
        }}
      >
        {children}
      </Auth0Context.Provider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadApp: () => dispatch(loadApp()),
  };
};

export const Auth0Provider = connect(
  null,
  mapDispatchToProps
)(Auth0ProviderDisconnected);
