import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  logo: {
    marginTop: 48,
    marginBottom: 32,
    width: '250px',
  },
  spinner: {},
}));

export default function FullPageLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="loading-page">
      <img
        className={classes.logo}
        alt="logo"
        src="https://www3.onshift.com/hubfs/content-library/misc/On_Shift_logo_2c.png"
      />
      <CircularProgress className={classes.spinner} />
    </div>
  );
}
