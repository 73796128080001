import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#0072CE' },
    secondary: { main: '#004C89' },
    hover: { main: '#03589C' },
    important: { main: '#B80707' },
  },
  transition: {
    duration: '0.3s',
  },
  navBar: {
    height: 48,
  },
});

export default theme;
