import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink as RouterLink } from 'react-router-dom';
import logo from '../images/logo-white.png';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import cx from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useAuth0 } from '../contexts/auth0Context';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    padding: 0,
    height: theme.navBar.height,
    minHeight: theme.navBar.height,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
      minHeight: 'initial',
    },
  },
  mobileNavMenuButton: {
    marginLeft: 16,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  mobileHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    height: 32,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 3,
    },
  },
  logoLink: {
    marginLeft: 140,
    marginRight: 91,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  navLinks: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.navBar.height,
      width: '100%',
      flexDirection: 'column',
    },
  },
  navLink: {
    width: 153,
    height: theme.navBar.height,
    borderRadius: 0,
    fontSize: '18px',
    fontFamily: 'Raleway',
    letterSpacing: '0px',
    textTransform: 'initial',
    color: '#FFFFFFDE',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `all ${theme.transition.duration} ease`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'initial',
      width: '100%',
      padding: '10px 0',
      backgroundColor: '#ffffff',
    },
    '&:hover': {
      backgroundColor: theme.palette.hover.main,
    },
  },
  activeNavLink: {
    width: 153,
    height: theme.navBar.height,
    borderRadius: 0,
    fontSize: '18px',
    fontFamily: 'Raleway',
    letterSpacing: '0px',
    textTransform: 'initial',
    color: '#000000DE',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000DE',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFFDE',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFFDE',
      },
    },
  },
  accountMenuButtonWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 140,
    [theme.breakpoints.down('sm')]: {
      marginRight: 23,
    },
  },
  logoutMenuItem: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  logoutButton: {
    textTransform: 'uppercase',
    boxShadow: '0px 1px 3px #00000033',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '1.25px',
    position: 'relative',
    justifyContent: 'center',
    width: '200px',
    margin: '15px auto',
  },
}));

export default function NavBar({ navItems }) {
  const classes = useStyles();
  const { isLoading, isAuthenticated, logout, user } = useAuth0();
  const [mobileNavMenuOpen, setMobileNavMenuOpenenuOpen] = useState(false);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);

  const handleClickaway = () => {
    if (mobileNavMenuOpen) setMobileNavMenuOpenenuOpen(false);
  };

  const navBarContents = () => (
    <React.Fragment>
      <IconButton
        edge="start"
        className={classes.mobileNavMenuButton}
        color="inherit"
        aria-label="mobile-nav-menu"
        onClick={() => setMobileNavMenuOpenenuOpen((o) => !o)}
      >
        <MenuIcon />
      </IconButton>
      <RouterLink to="/" className={classes.logoLink}>
        <img className={classes.logo} alt="logo" src={logo} />
      </RouterLink>
      <div
        className={cx(classes.navLinks, {
          [classes.mobileHidden]: !mobileNavMenuOpen,
        })}
      >
        {navItems
          .filter((navItem) => !navItem.isHiddenFromNav)
          .map((navItem) => (
            <RouterLink
              key={navItem.pathPrefix}
              to={`/${navItem.pathPrefix}`}
              className={({ isActive }) =>
                isActive ? classes.activeNavLink : classes.navLink
              } // NOSONAR
            >
              {navItem.title}
            </RouterLink>
          ))}
      </div>
      <div className={classes.accountMenuButtonWrapper}>
        <IconButton
          edge="end"
          className={classes.accountMenuButton}
          color="inherit"
          aria-label="account-menu"
          aria-controls="account-menu"
          aria-haspopup="true"
          onClick={(e) => setAccountMenuAnchorEl(e.currentTarget)}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id="account-menu"
          anchorEl={accountMenuAnchorEl}
          keepMounted
          open={Boolean(accountMenuAnchorEl)}
          onClose={() => setAccountMenuAnchorEl(null)}
        >
          <MenuItem disabled={true}>{user.name}</MenuItem>
          <MenuItem className={classes.logoutMenuItem}>
            <Button
              color="primary"
              variant="contained"
              className={classes.logoutButton}
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log Out
            </Button>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  );

  return (
    <div className={classes.root} data-testid="nav-bar">
      <AppBar position="static" className={classes.appbar} elevation={0}>
        <ClickAwayListener
          onClickAway={handleClickaway}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <Toolbar className={classes.toolbar}>
            {isAuthenticated && !isLoading ? (
              navBarContents()
            ) : (
              <span>&nbsp;</span>
            )}
          </Toolbar>
        </ClickAwayListener>
      </AppBar>
    </div>
  );
}
