import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { get } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { formatUtcDateTimeToLocal } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 20px 20px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subheader: {
    fontSize: '0.75rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function SentBatch({ messageBatch, isOpen, onClose, onDelete }) {
  const classes = useStyles();
  const chainId = get(messageBatch, 'recipientIds[0]');
  const chainName = get(messageBatch, `recipientNames[${chainId}]`);
  const sentTime = formatUtcDateTimeToLocal(
    get(messageBatch, 'sentTime', null)
  );
  const sender = get(messageBatch, 'senderName', 'Unknown User');

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div>Message to {chainName}</div>
        <div className={classes.subheader}>
          <div>From: {sender}</div>
          <div>{sentTime}</div>
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{get(messageBatch, 'defaultMessageBody')}</DialogContent>
      {onDelete && (
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={onDelete}
          >
            Delete
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
