// adapted from https://dev.to/aaronpowell/combining-react-hooks-with-appinsights-5692

import React, { createContext } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from '../history';
import { getEnvVarByName } from '../utils';

const reactPlugin = new ReactPlugin();
const AppInsightsContext = createContext(reactPlugin);
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: getEnvVarByName('REACT_APP_APPLICATION_INSIGHTS_KEY'),
    enableAjaxErrorStatusText: true,
    disableFetchTracking: false,
    enableUnhandledPromiseRejectionTracking: true,
    // enableAjaxPerfTracking produces a ton of noise and ultimately doesn't give us much more than what we get from backend instrumentation
    // enableAjaxPerfTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});
const initializeAi = () => {
  try {
    ai.loadAppInsights();

    // make errors serializable
    if (!('toJSON' in Error.prototype)) {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Error.prototype, 'toJSON', {
        value: function () {
          const alt = {};
          Object.getOwnPropertyNames(this).forEach(function (key) {
            alt[key] = this[key];
          }, this);
          return alt;
        },
        configurable: true,
        writable: true,
      });
    }

    // also send console errors, warnings, and logs to app insights
    window.console.errorOrig = window.console.error;
    window.console.warnOrig = window.console.warn;
    window.console.logOrig = window.console.error;
    window.console.error = (...args) => {
      ai.trackTrace({ message: JSON.stringify(args), severityLevel: 3 });
      console.errorOrig(...args);
    };
    window.console.warn = (...args) => {
      ai.trackTrace({ message: JSON.stringify(args), severityLevel: 2 });
      console.warnOrig(...args);
    };
    window.console.log = (...args) => {
      ai.trackTrace({ message: JSON.stringify(args), severityLevel: 1 });
      console.logOrig(...args);
    };
  } catch (err) {
    console.error(
      'Failure while loading app insights. Diagnostics will not be recorded.',
      err
    );
  }
};
initializeAi();

export const AppInsightsContextProvider = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};
