import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { getSnackbar } from '../store/selectors';
import { closeSnackbar } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    top: 65,
  },
}));

export default function SimpleSnackbar() {
  const classes = useStyles();
  const snackbarState = useSelector((state) => getSnackbar(state));
  const dispatch = useDispatch();

  const handleClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      className={classes.snackbar}
      open={snackbarState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      data-testid="snackbar"
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={snackbarState.variant}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}
