import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/core';
import theme from './material-ui-theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Auth0Provider } from './contexts/auth0Context';
import { AppInsightsContextProvider } from './contexts/applicationInsightsContext';
import store from './store/store';
import history from './history';

ReactDOM.render(
  <Router navigator={history}>
    <ReduxProvider store={store}>
      <AppInsightsContextProvider>
        <Auth0Provider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Auth0Provider>
      </AppInsightsContextProvider>
    </ReduxProvider>
  </Router>,
  document.getElementById('root')
);
