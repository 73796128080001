import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { cancelScheduledRequest } from '../../services/carrierDactylService';
import { openSnackbar } from '../../store/actions';
import { snackBarVariant } from '../../types/snackBar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 20px 20px',
  },
}));

export default function ConfirmCancelScheduledRequest({
  requestSequenceNumberToCancel,
  isOpen,
  onClose,
  onSetExecutingRequest,
  onRefreshScheduledRequests,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleYes = async () => {
    onSetExecutingRequest(true);
    const response = await cancelScheduledRequest(
      requestSequenceNumberToCancel
    );
    onSetExecutingRequest(false);
    onClose();
    if (!response.success) {
      dispatch(
        openSnackbar(
          `Failed to cancel scheduled message. ${response.errorMessage}.`,
          snackBarVariant.error
        )
      );
      return;
    }
    dispatch(
      openSnackbar('Cancelled Scheduled Request', snackBarVariant.success)
    );

    // now reload the current page of scheduled requests to show its gone
    await onRefreshScheduledRequests();
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="confirm-cancel"
    >
      <DialogTitle id="alert-dialog-title">
        Cancel Scheduled Message(s)?
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Do Not Cancel
        </Button>
        <Button
          onClick={handleYes}
          color="primary"
          variant="contained"
          autoFocus
          disableElevation
        >
          Confirm Cancellation
        </Button>
      </DialogActions>
    </Dialog>
  );
}
