import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { fetchMessageBatches } from '../../services/carrierDactylService';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../store/actions';
import { snackBarVariant } from '../../types/snackBar';
import ContentHeader from '../Shared/ContentHeader';
import SentBatch from '../Shared/SentBatch';
import SentBatchList from '../Shared/SentBatchList';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useCancellablePromise } from '../../utils';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginLeft: 30,
  },
  refreshIcon: {
    cursor: 'pointer',
    marginLeft: 30,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    transition: 'color 0.3s ease',
  },
}));

export default function SentMessages() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [executingRequest, setExecutingRequest] = useState(false);
  const [messageBatchList, setMessageBatchList] = useState([]);
  const [selectedMessageBatch, setSelectedMessageBatch] = useState({});
  const [paginationData, setPaginationData] = useState({});
  const [showMessageBatchDialog, setShowMessageBatchDialog] = useState(false);
  const rowsPerListPage = 10;
  const { cancellablePromise } = useCancellablePromise();

  const handleRefreshMessageBatches = async () => {
    const currentPage = paginationData.PageIndex || 0;
    handleFetchMessageBatches(currentPage);
  };

  const handleFetchMessageBatches = async (page) => {
    setExecutingRequest(true);
    let response;
    try {
      response = await cancellablePromise(
        fetchMessageBatches(rowsPerListPage, page)
      );
    } catch (err) {
      if (err.isCanceled) return;
      throw err;
    }
    if (response.success) {
      setMessageBatchList(response.data);
      setPaginationData(response.pagination);
    } else {
      dispatch(
        openSnackbar(
          `Failed to get batches. ${response.errorMessage}.`,
          snackBarVariant.error
        )
      );
    }
    setExecutingRequest(false);
    return response.success;
  };

  const handleSelectBatchFromList = (messageBatch) => {
    setSelectedMessageBatch(messageBatch);
    setShowMessageBatchDialog(true);
  };

  // fetch first page on mount
  useEffect(() => {
    handleFetchMessageBatches(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ContentHeader
        title="Sent Messages"
        subtitle={
          <React.Fragment>
            View sent messages.
            {executingRequest ? (
              <CircularProgress className={classes.spinner} size={20} />
            ) : (
              <RefreshIcon
                className={classes.refreshIcon}
                onClick={handleRefreshMessageBatches}
              />
            )}
          </React.Fragment>
        }
      />
      <SentBatchList
        batchList={messageBatchList}
        onSelectBatch={handleSelectBatchFromList}
        rowsPerPage={rowsPerListPage}
        onChangePage={handleFetchMessageBatches}
        paginationData={paginationData}
        executingRequest={executingRequest}
      />
      <SentBatch
        messageBatch={selectedMessageBatch}
        isOpen={showMessageBatchDialog}
        onClose={() => setShowMessageBatchDialog(false)}
      />
    </React.Fragment>
  );
}
