import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'normal',
    textAlign: 'left',
    fontSize: '36px',
    letterSpacing: '0px',
    color: '#002F6D',
    marginBottom: '32.5px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '30px',
    },
  },
  subtitle: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#000000DE',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '16px',
    },
  },
}));

export default function ContentHeader({ title, subtitle }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.subtitle}>{subtitle}</div>
    </React.Fragment>
  );
}
