import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  NavLink as RouterLink,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getLoading } from '../../store/selectors';
import { PrivateRoute } from '../PrivateRoute';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  content: {
    overflow: 'hidden',
    maxWidth: 'calc(100% - 325px)',
    width: 'calc(100% - 325px)',
    height: '100%',
    marginTop: 20,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 'initial',
      width: 'initial',
      maxWidth: 'initial',
    },
  },
  sideNav: {
    padding: 0,
    marginTop: 91,
    textAlign: 'left',
    marginLeft: 100,
    width: 325,
    minWidth: 325,
    [theme.breakpoints.down('sm')]: {
      margin: 'initial',
      width: 'initial',
      textAlign: 'center',
    },
    '& li': {
      listStyle: 'none',
      marginBottom: '29px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0',
      },
    },
  },
  sideNavLink: {
    fontSize: '16px',
    color: '#000000DE',
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:visited, &:hover, &:active': {
      textDecoration: 'unset',
      color: '#000000DE',
    },
  },
  activeNavLink: {
    fontSize: '16px',
    color: '#002F6D',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:visited, &:hover, &:active': {
      color: '#002F6D',
    },
  },
  loadingRoot: {
    height: '100%',
    marginTop: 20,
    textAlign: 'center',
  },
}));

export default function ContentWithSidebar({ pathPrefix, sideBarItems }) {
  const classes = useStyles();
  const appLoading = useSelector((state) => getLoading(state));

  if (appLoading.inProgress)
    return (
      <div className={classes.loadingRoot}>
        <CircularProgress />
      </div>
    );

  if (appLoading.failed) return null;

  if (!sideBarItems || sideBarItems.length === 0) {
    console.warn('No sideBarItems defined for page');
    return null;
  }

  if (sideBarItems.length === 1)
    return (
      <div className={classes.root}>
        <div className={classes.sideNav}></div>
        <div className={classes.content}>
          <Routes>
            <Route path="/" element={sideBarItems[0].component} />
          </Routes>
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      <ul className={classes.sideNav}>
        {sideBarItems.map((item) => (
          <li key={item.path}>
            <RouterLink
              to={`/${pathPrefix}/${item.path}`}
              className={({ isActive }) =>
                isActive ? classes.activeNavLink : classes.sideNavLink
              } // NOSONAR
            >
              {item.title}
            </RouterLink>
          </li>
        ))}
      </ul>

      <div className={classes.content}>
        <Routes>
          {sideBarItems.map((item) => (
            <Route
              key={item.path}
              path={`/${item.path}`}
              element={<PrivateRoute>{item.component}</PrivateRoute>}
            />
          ))}
          <Route
            path="/"
            element={
              <Navigate to={`/${pathPrefix}/${sideBarItems[0].path}`} replace />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
