import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { syncMailGunUnsubscribed } from '../../services/carrierDactylService';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../store/actions';
import { snackBarVariant } from '../../types/snackBar';
import ContentHeader from '../Shared/ContentHeader';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    boxShadow: '0px 1px 3px #00000033',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '1.25px',
    position: 'relative',
    width: '328px',
    margin: '18px 0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 45px)',
      margin: '18px 22.5px',
    },
  },
  formAlert: {
    width: '294px',
    display: 'flex',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 78px)',
      marginLeft: '22.5px',
      marginRight: '22.5px',
    },
  },
}));

export default function AdministratorActions() {
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSyncMailGunUnsubscribed = async () => {
    setSubmitting(true);
    const reportResponse = await syncMailGunUnsubscribed();
    if (reportResponse.success) {
      dispatch(openSnackbar('Synced Successfully', snackBarVariant.success));
    } else {
      dispatch(
        openSnackbar(
          `Failed to Sync. ${reportResponse.errorMessage}.`,
          snackBarVariant.error
        )
      );
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <ContentHeader
        title="Administrator Actions"
        subtitle="Perform Administrator Actions."
      />
      <Alert
        variant="outlined"
        severity="warning"
        className={classes.formAlert}
      >
        Many of these actions are dangerous. Please use caution.
      </Alert>
      <Button
        color="primary"
        onClick={handleSyncMailGunUnsubscribed}
        className={classes.submitButton}
        disabled={submitting}
        variant="contained"
        data-testid="admin-action"
      >
        Sync Unsubscribe List from MailGun
      </Button>
    </React.Fragment>
  );
}
