import React from 'react';
import NavBar from './components/NavBar';
import Snackbar from './components/Snackbar';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import { makeStyles } from '@material-ui/core/styles';
import { PrivateRoute } from './components/PrivateRoute';
import ContentWithSidebar from './components/Shared/ContentWithSidebar';
import MessageChainForm from './components/MessageChain/MessageChainForm';
import OpenShiftForm from './components/OpenShift/OpenShiftForm';
import SentMessages from './components/SentMessages/SentMessages';
import ScheduledMessages from './components/ScheduledMessages/ScheduledMessages';
import AdministratorActions from './components/Admin/AdministratorActions';
import AddressReportByType from './components/Reports/AddressReportByType';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileWarning: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      display: 'flex',
      '& div': {
        letterSpacing: '0px',
        color: '#000000DE',
        textAlign: 'center',
        fontSize: '16px',
      },
    },
  },
  main: {
    height: `calc(100vh - ${theme.navBar.height}px)`,
    minHeight: 500,
  },
}));

const navItems = [
  {
    title: 'Communication',
    pathPrefix: 'communication',
    subNavItems: [
      {
        title: 'Message a Chain',
        path: 'message-chain',
        component: <MessageChainForm />,
      },
      {
        title: 'Sent Messages',
        path: 'sent-messages',
        component: <SentMessages />,
      },
      {
        title: 'Scheduled Messages',
        path: 'scheduled-messages',
        component: <ScheduledMessages />,
      },
    ],
  },
  {
    title: 'Reports',
    isHiddenFromNav: true,
    pathPrefix: 'reports',
    subNavItems: [
      {
        title: 'Address Delivery Report',
        path: 'address-delivery-report',
        component: (
          <AddressReportByType
            title="Address Delivery Report"
            subtitle="Identify Invalid Addresses."
            serviceToFetch={1}
            csvName="addressDeliveryReport.csv"
          />
        ),
      },
      {
        title: 'Unsubscribed Address Report',
        path: 'unsubscribed-address-report',
        component: (
          <AddressReportByType
            title="Unsubscribed Address Report"
            subtitle="Identify Addresses that have Unsubscribed from OnShift."
            serviceToFetch={2}
            csvName="unsubscribedAddressReport.csv"
          />
        ),
      },
    ],
  },
  {
    title: 'Admin',
    isHiddenFromNav: true,
    pathPrefix: 'admin',
    subNavItems: [
      {
        title: 'Administrator Actions',
        path: 'admin-actions',
        component: <AdministratorActions />,
      },
    ],
  },
  {
    title: 'Alpha',
    isLocalhostOnly: true,
    pathPrefix: 'alpha',
    subNavItems: [
      {
        title: 'Test Open Shift',
        path: 'open-shift',
        component: <OpenShiftForm />,
      },
    ],
  },
];

function App() {
  const classes = useStyles();
  const navItemsToUse = navItems.filter(
    (navItem) =>
      !navItem.isLocalhostOnly || window.location.hostname === 'localhost'
  );

  const mainRoutes = navItemsToUse.map((navItem) => {
    const component = (
      <ContentWithSidebar
        sideBarItems={navItem.subNavItems}
        pathPrefix={navItem.pathPrefix}
      />
    );
    return (
      <Routes key={navItem.title}>
        <Route
          path={`/${navItem.pathPrefix}/*`}
          element={<PrivateRoute>{component}</PrivateRoute>}
        />
      </Routes>
    );
  });

  return (
    <React.Fragment>
      <div className={classes.root}>
        <NavBar navItems={navItemsToUse} />
        <Snackbar />
        <div className={classes.main}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/*" element={mainRoutes} />
          </Routes>
        </div>
      </div>
      <div className={classes.mobileWarning}>
        <div>Please access via a desktop browser.</div>
      </div>
    </React.Fragment>
  );
}

export default App;
