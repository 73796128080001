import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import {
  postMessageV1,
  messageTypes,
  recipientTypes,
} from '../../services/carrierDactylService';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import ContentHeader from '../Shared/ContentHeader';

const useStyles = makeStyles((theme) => ({
  formField: {
    position: 'relative',
    width: '328px',
    margin: '18px 0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 45px)',
      margin: '18px 22.5px',
    },
  },
  formAlert: {
    width: '294px',
    display: 'flex',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 78px)',
      marginLeft: '22.5px',
      marginRight: '22.5px',
    },
  },
  submitRow: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  submitButton: {
    boxShadow: '0px 1px 3px #00000033',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '1.25px',
    width: '100%',
  },
  submitSpinner: {
    position: 'absolute',
    left: '100%',
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      marginLeft: 0,
      marginTop: 15,
    },
  },
}));

export default function OpenShiftForm() {
  const classes = useStyles();
  const [formIsValid, setFormIsValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(true);
  const [submitResponse, setSubmitResponse] = useState(null);

  const initialFormState = {
    organizationId: 49,
    userIdsCommaSeparated: '2580872',
    shiftTypeId: 70,
    locationId: 99,
    shiftStartTime: moment().add(30, 'minutes'),
    shiftEndTime: moment().add(90, 'minutes'),
  };
  const [formValues, setFormValues] = useState({ ...initialFormState });

  const handleShiftStartTimeChange = (value) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      shiftStartTime: value,
    }));
  };
  const handleShiftEndTimeChange = (value) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      shiftEndTime: value,
    }));
  };
  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [name]: value,
    }));
  };
  const handleFormSubmit = async () => {
    const postMessageParams = {
      messageType: messageTypes.openShiftV1,
      recipientType: recipientTypes.employee,
      recipientIds: formValues.userIdsCommaSeparated
        .split(',')
        .map((i) => parseInt(i))
        .filter((i) => !isNaN(i)),
      propertyBag: {
        MessageBody: formValues.messageBody,
        ShiftStartTime: formValues.shiftStartTime.toISOString(),
        ShiftEndTime: formValues.shiftEndTime.toISOString(),
        ShiftId: 1,
        ShiftTypeId: parseInt(formValues.shiftTypeId),
        ShiftLocationId: parseInt(formValues.locationId),
        OrganizationId: parseInt(formValues.organizationId),
      },
    };
    setSubmitResponse(null);
    setSubmitting(true);
    const response = await postMessageV1(postMessageParams);
    setSubmitting(false);
    setSubmitSuccessful(response.success);
    setSubmitResponse(
      response.success ? JSON.stringify(response.data) : response.errorMessage
    );
  };

  useEffect(() => {
    let updatedFormIsValid = true;
    if (!formValues.organizationId || isNaN(formValues.organizationId))
      updatedFormIsValid = false;
    if (!formValues.locationId || isNaN(formValues.locationId))
      updatedFormIsValid = false;
    if (!formValues.shiftTypeId || isNaN(formValues.shiftTypeId))
      updatedFormIsValid = false;
    if (
      !formValues.userIdsCommaSeparated ||
      formValues.userIdsCommaSeparated.split(',').length === 0 ||
      formValues.userIdsCommaSeparated.split(',').filter((i) => !isNaN(i))
        .length !== formValues.userIdsCommaSeparated.split(',').length
    )
      updatedFormIsValid = false;
    if (
      !formValues.shiftStartTime ||
      formValues.shiftStartTime.isBefore(moment())
    )
      updatedFormIsValid = false;
    if (!formValues.shiftEndTime || formValues.shiftEndTime.isBefore(moment()))
      updatedFormIsValid = false;
    setFormIsValid(updatedFormIsValid);
  }, [formValues]);

  return (
    <React.Fragment>
      <ContentHeader
        title="Send an Open Shift Message"
        subtitle="Send an Open Shift Message to a Set of Users in a Community."
      />
      <form onSubmit={(e) => e.preventDefault()} data-testid="open-form">
        <TextField
          className={classes.formField}
          label="Organization ID"
          variant="outlined"
          name="organizationId"
          onChange={handleFormChange}
          value={formValues.organizationId}
        />
        <TextField
          className={classes.formField}
          label="User IDs (Comma Separated)"
          variant="outlined"
          name="userIdsCommaSeparated"
          onChange={handleFormChange}
          value={formValues.userIdsCommaSeparated}
        />
        <TextField
          className={classes.formField}
          label="Location ID"
          variant="outlined"
          name="locationId"
          onChange={handleFormChange}
          value={formValues.locationId}
        />
        <TextField
          className={classes.formField}
          label="Shift Type ID"
          variant="outlined"
          name="shiftTypeId"
          onChange={handleFormChange}
          value={formValues.shiftTypeId}
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <React.Fragment>
            <DateTimePicker
              className={classes.formField}
              value={formValues.shiftStartTime}
              onChange={handleShiftStartTimeChange}
              label="Shift Start Time"
              inputVariant="outlined"
              disablePast={true}
            />
            <DateTimePicker
              className={classes.formField}
              value={formValues.shiftEndTime}
              onChange={handleShiftEndTimeChange}
              label="Shift End Time"
              inputVariant="outlined"
              disablePast={true}
            />
            <Alert
              variant="outlined"
              severity="info"
              className={classes.formAlert}
            >
              This is based on your local timezone.
            </Alert>
          </React.Fragment>
        </MuiPickersUtilsProvider>
        <div className={cx(classes.formField, classes.submitRow)}>
          <Button
            color="primary"
            onClick={handleFormSubmit}
            className={classes.submitButton}
            disabled={!formIsValid || submitting}
            variant="contained"
          >
            Submit
          </Button>
          {submitting && <CircularProgress className={classes.submitSpinner} />}
        </div>
      </form>
      {submitResponse && (
        <Alert
          variant="outlined"
          severity={submitSuccessful ? 'info' : 'error'}
        >
          {submitResponse}
        </Alert>
      )}
    </React.Fragment>
  );
}
