import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { openSnackbar } from '../../store/actions';
import cx from 'classnames';
import {
  postMessageV1,
  messageTypes,
  recipientTypes,
} from '../../services/carrierDactylService';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { snackBarVariant } from '../../types/snackBar';
import ChainAutocomplete from './ChainAutocomplete';
import ContentHeader from '../Shared/ContentHeader';

const useStyles = makeStyles((theme) => ({
  formField: {
    position: 'relative',
    width: '328px',
    margin: '18px 0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 45px)',
      margin: '18px 22.5px',
    },
  },
  formAlert: {
    width: '294px',
    display: 'flex',
    textAlign: 'left',
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 78px)',
      marginLeft: '22.5px',
      marginRight: '22.5px',
    },
  },
  submitRow: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  submitButton: {
    boxShadow: '0px 1px 3px #00000033',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '1.25px',
    width: '100%',
  },
  switch: {
    justifyContent: 'space-between',
    '& > span': {
      marginRight: -6,
    },
  },
  checkbox: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'initial',
    },
  },
  checkboxBox: {
    marginLeft: -12,
  },
  hidden: {
    display: 'none',
  },
  submitSpinner: {
    position: 'absolute',
    left: '100%',
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      marginLeft: 0,
      marginTop: 15,
    },
  },
}));

const PrioritySwitch = withStyles((theme) => ({
  root: {
    marginRight: -6,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.important.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.important.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const setMessageText = (formValues) => {
  let messageText = '';

  if (formValues.sendInFuture) {
    messageText = setSchedulingText(formValues);
  } else {
    messageText = setSendingText(formValues);
  }

  return messageText;
};

const setSchedulingText = (formValues) => {
  let messageText = '';

  if (formValues.chain) {
    if (formValues.chain.memberCount === 1) {
      messageText = 'Schedule Message to 1 Person';
    } else {
      messageText = `Schedule Message to ${formValues.chain.memberCount} People`;
    }
  } else {
    messageText = 'Schedule Message';
  }

  return messageText;
};

const setSendingText = (formValues) => {
  let messageText = '';

  if (formValues.chain) {
    if (formValues.chain.memberCount === 1) {
      messageText = 'Send Message to 1 Person';
    } else {
      messageText = `Send Message to ${formValues.chain.memberCount} People`;
    }
  } else {
    messageText = 'Send Message';
  }

  return messageText;
};

export default function MessageChainForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formIsValid, setFormIsValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // note that if the message is split into multiple, then each can only be 153 characters
  const maxSmsSegmentLength = 160;

  const initialFormState = {
    chain: null,
    isImportant: false,
    useShortMessage: false,
    messageBody: null,
    shortMessageBody: null,
    sendInFuture: false,
    timeToSend: moment().add(30, 'minutes'),
  };
  const [formValues, setFormValues] = useState({ ...initialFormState });

  const handleTimeToSendChange = (value) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      timeToSend: value,
    }));
  };

  const handleChainChange = (value) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      chain: value,
    }));
  };

  const toggleFormValue = (e) => {
    const name = e.target.name;
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [name]: !previousFormValues[name],
    }));
  };

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    const priorityName = formValues.isImportant ? 'Important' : 'Standard';

    const postMessageParams = {
      messageType: messageTypes.chainGeneral,
      recipientType: recipientTypes.chain,
      recipientIds: [formValues.chain.id],
      propertyBag: {
        MessageBody: formValues.messageBody,
        PriorityName: priorityName,
      },
    };
    if (formValues.useShortMessage) {
      postMessageParams.propertyBag.ShortMessageBody =
        formValues.shortMessageBody;
    }
    if (formValues.sendInFuture) {
      postMessageParams.timeToSend = formValues.timeToSend.toISOString();
    }
    setSubmitting(true);
    const response = await postMessageV1(postMessageParams);
    setSubmitting(false);
    handleResponse(response, formValues);
  };

  const handleResponse = (response, formValuesInner) => {
    if (response.success) {
      dispatch(
        openSnackbar(
          getSuccessMessage(formValuesInner),
          snackBarVariant.success
        )
      );
    } else {
      dispatch(
        openSnackbar(
          `Failed to ${
            formValuesInner.sendInFuture ? 'schedule' : 'send'
          } message: ${response.errorMessage}`,
          snackBarVariant.error
        )
      );
    }
  };

  const getSuccessMessage = (formValuesInner) => {
    if (formValuesInner.sendInFuture) {
      return 'Scheduled Message(s)';
    } else {
      return 'Sent Message(s)';
    }
  };

  useEffect(() => {
    let updatedFormIsValid = true;
    if (!formValues.chain) updatedFormIsValid = false;
    if (!formValues.messageBody || formValues.messageBody.trim() === '') {
      updatedFormIsValid = false;
    }

    if (
      formValues.messageBody &&
      formValues.chain &&
      formValues.messageBody.length >
        maxSmsSegmentLength - formValues.chain.usedSmsMessageCharacters &&
      !formValues.useShortMessage
    ) {
      updatedFormIsValid = false;
    }

    if (
      formValues.useShortMessage &&
      (!formValues.shortMessageBody ||
        formValues.shortMessageBody.trim() === '')
    ) {
      updatedFormIsValid = false;
    }

    if (
      formValues.sendInFuture &&
      (!formValues.timeToSend || formValues.timeToSend.isBefore(moment()))
    ) {
      updatedFormIsValid = false;
    }
    setFormIsValid(updatedFormIsValid);
  }, [formValues]);

  const messageText = setMessageText(formValues);

  return (
    <React.Fragment>
      <ContentHeader
        title="Message a Chain"
        subtitle="Send a Message to All Members of a Chain."
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <ChainAutocomplete onChainChange={handleChainChange} />
        {formValues.chain && (
          <Alert
            variant="outlined"
            severity="info"
            className={classes.formAlert}
          >
            This chain has {formValues.chain.memberCount}&nbsp;
            {formValues.chain.memberCount === 1 ? 'member' : 'members'}.
          </Alert>
        )}
        <TextField
          data-testid="message-chain"
          id="long-input"
          className={classes.formField}
          label="Message"
          multiline={true}
          variant="outlined"
          name="messageBody"
          minRows={3}
          onChange={handleFormChange}
        />
        {formValues.messageBody &&
        formValues.chain &&
        formValues.messageBody.length >
          maxSmsSegmentLength - formValues.chain.usedSmsMessageCharacters &&
        !formValues.useShortMessage ? (
          <Alert
            variant="outlined"
            severity="error"
            className={classes.formAlert}
          >
            This is a long message that is well suited for email.&nbsp; We
            require a shorter one for push and SMS recipients.
          </Alert>
        ) : null}
        <FormControlLabel
          className={cx(classes.formField, classes.checkbox)}
          control={
            <Checkbox
              className={classes.checkboxBox}
              checked={formValues.useShortMessage}
              onChange={toggleFormValue}
              name="useShortMessage"
            />
          }
          label="Use a Shorter Message for Push and SMS Recipients"
        />
        <TextField
          className={cx(classes.formField, {
            [classes.hidden]: !formValues.useShortMessage,
          })}
          label="Short Message"
          multiline={true}
          variant="outlined"
          name="shortMessageBody"
          minRows={3}
          onChange={handleFormChange}
        />
        {formValues.useShortMessage &&
        formValues.shortMessageBody &&
        formValues.chain &&
        formValues.shortMessageBody.length >
          maxSmsSegmentLength - formValues.chain.usedSmsMessageCharacters ? (
          <Alert
            variant="outlined"
            severity="warning"
            className={classes.formAlert}
          >
            This message may result in multiple segments (texts) for any SMS
            recipients. We recommend shortening it if possible.
          </Alert>
        ) : null}
        <Alert variant="outlined" severity="info" className={classes.formAlert}>
          Only mark a message important when it requires immediate action from
          employees.
        </Alert>
        <FormControlLabel
          className={cx(classes.formField, classes.switch)}
          control={
            <PrioritySwitch
              checked={formValues.isImportant}
              onChange={toggleFormValue}
              name="isImportant"
            />
          }
          label="Message is Important"
          labelPlacement="start"
        />
        <FormControlLabel
          className={cx(classes.formField, classes.switch)}
          control={
            <Switch
              color="primary"
              checked={formValues.sendInFuture}
              onChange={toggleFormValue}
              name="sendInFuture"
            />
          }
          label="Send in the Future"
          labelPlacement="start"
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <React.Fragment>
            <DateTimePicker
              className={cx(classes.formField, {
                [classes.hidden]: !formValues.sendInFuture,
              })}
              value={formValues.timeToSend}
              onChange={handleTimeToSendChange}
              label="Time to Send"
              inputVariant="outlined"
              disablePast={true}
              maxDate={moment().add(48, 'hours')}
            />
            {formValues.sendInFuture ? (
              <Alert
                variant="outlined"
                severity="info"
                className={classes.formAlert}
              >
                This is based on your local timezone, not the recipient's
                timezone.
              </Alert>
            ) : null}
          </React.Fragment>
        </MuiPickersUtilsProvider>
        <div className={cx(classes.formField, classes.submitRow)}>
          <Button
            data-testid="submit-button"
            color="primary"
            onClick={handleFormSubmit}
            className={classes.submitButton}
            disabled={!formIsValid || submitting}
            variant="contained"
          >
            {messageText}
          </Button>
          {submitting && <CircularProgress className={classes.submitSpinner} />}
        </div>
      </form>
    </React.Fragment>
  );
}
