import { useRef, useEffect } from 'react';
import moment from 'moment-timezone';

export function getEnvVarByName(name) {
  // When runnign via docker, we inject the env vars into a config.js file which is loaded and attaches the vars
  // to the window object. This let's them be assigned at runtime rather than at build time
  // For local dev, we still assign at build time by using the .env file
  if (window[name]) return window[name];
  if (process.env[name]) return process.env[name];
  console.warn(`Failed to find env var with name '${name}'`);
  return null;
}

// pulled from https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const useCancellablePromise = () => {
  const promises = useRef();

  useEffect(() => {
    promises.current = promises.current || [];
    return function cancel() {
      promises.current.forEach((p) => p.cancel());
      promises.current = [];
    };
  }, []);

  function cancellablePromise(p) {
    const cPromise = makeCancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }
  return { cancellablePromise };
};

export const formatUtcDateTimeToLocal = (dateTime) => {
  const momentDateTime = moment.utc(dateTime);
  if (!momentDateTime.isValid() || momentDateTime.year() < 2) return 'N/A';
  const zoneName = moment.tz.guess();
  const zoneAbbr = moment.tz.zone(zoneName).abbr(momentDateTime.unix());
  return `${momentDateTime.local().format('MMM Do LT')} ${zoneAbbr}`;
};
