import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import { fetchScheduledRequests } from '../../services/carrierDactylService';
import ContentHeader from '../Shared/ContentHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';
import { openSnackbar } from '../../store/actions';
import { snackBarVariant } from '../../types/snackBar';
import ConfirmCancelScheduledRequest from './ConfirmCancelScheduledRequest';
import { useCancellablePromise } from '../../utils';
import SentBatchList from '../Shared/SentBatchList';
import SentBatch from '../Shared/SentBatch';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginLeft: 30,
  },
  refreshIcon: {
    cursor: 'pointer',
    marginLeft: 30,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    transition: 'color 0.3s ease',
  },
}));

export default function ScheduledMessages() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cancellablePromise } = useCancellablePromise();

  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [showMessageBatchDialog, setShowMessageBatchDialog] = useState(false);
  const [selectedMessageBatch, setSelectedMessageBatch] = useState({});
  const [executingRequest, setExecutingRequest] = useState(false);
  const [scheduledRequests, setScheduledRequests] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  // paging state and handlers
  const pageSize = 10;
  const handleChangePage = async (newPage) => {
    return await handleGetScheduledRequests(newPage);
  };

  // populating scheduled request list handlers
  const handleRefreshScheduledRequests = async () => {
    const currentPage = paginationData.PageIndex || 0;
    handleGetScheduledRequests(currentPage);
  };
  const handleGetScheduledRequests = async (page) => {
    setExecutingRequest(true);
    let response;
    try {
      response = await cancellablePromise(
        fetchScheduledRequests(page, pageSize)
      );
    } catch (err) {
      if (err.isCanceled) return;
      throw err;
    }
    setExecutingRequest(false);
    if (!response.success) {
      dispatch(
        openSnackbar(
          `Failed to get scheduled messages. ${response.errorMessage}.`,
          snackBarVariant.error
        )
      );
      return false;
    }
    setScheduledRequests([...response.data]);
    setPaginationData(response.pagination);
    return true;
  };

  const handleSelectBatch = (messageBatch) => {
    setSelectedMessageBatch(messageBatch);
    setShowMessageBatchDialog(true);
  };

  const handleCloseConfirmCancelDialog = () => {
    setShowConfirmCancelDialog(false);
    setShowMessageBatchDialog(false);
  };

  // fetch first page on mount
  useEffect(() => {
    handleGetScheduledRequests(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ConfirmCancelScheduledRequest
        requestSequenceNumberToCancel={get(
          selectedMessageBatch,
          'scheduledRequestSequenceNumber'
        )}
        isOpen={showConfirmCancelDialog}
        onClose={() => handleCloseConfirmCancelDialog()}
        onSetExecutingRequest={setExecutingRequest}
        onRefreshScheduledRequests={handleRefreshScheduledRequests}
      />
      <ContentHeader
        title="Scheduled Messages"
        subtitle={
          <React.Fragment>
            View and cancel scheduled messages.
            {executingRequest ? (
              <CircularProgress className={classes.spinner} size={20} />
            ) : (
              <RefreshIcon
                className={classes.refreshIcon}
                onClick={handleRefreshScheduledRequests}
              />
            )}
          </React.Fragment>
        }
      />
      <SentBatchList
        batchList={scheduledRequests}
        onSelectBatch={handleSelectBatch}
        rowsPerPage={pageSize}
        onChangePage={handleChangePage}
        paginationData={paginationData}
        executingRequest={executingRequest}
      />
      <SentBatch
        messageBatch={selectedMessageBatch}
        isOpen={showMessageBatchDialog}
        onClose={() => setShowMessageBatchDialog(false)}
        onDelete={() => setShowConfirmCancelDialog(true)}
      />
    </React.Fragment>
  );
}
