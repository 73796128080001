import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { formatUtcDateTimeToLocal } from '../../utils';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '60vh',
    maxWidth: '90%',
    margin: '20px 0',
    border: '1px #ccc solid',
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
  },
  textCell: {
    maxWidth: '20vw',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
  },
}));

export default function SentBatchList({
  batchList,
  onSelectBatch,
  rowsPerPage,
  onChangePage,
  paginationData,
  executingRequest,
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const handleChangePage = async (_e, newPage) => {
    const success = await onChangePage(newPage, page);
    if (success) {
      setPage(newPage);
    }
  };

  return (
    <TableContainer className={classes.tableContainer} data-testid="sent-list">
      <Table stickyHeader size="small" style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>Sender</TableCell>
            <TableCell>Chain</TableCell>
            <TableCell>Body</TableCell>
            <TableCell>Send Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batchList.map((item) => {
            const chainId = get(item, 'recipientIds[0]');
            const chainName = get(item, `recipientNames[${chainId}]`);
            const body = item.defaultMessageBody.replace(
              `This is an important message from ${chainName}. `,
              ''
            );
            return (
              <TableRow
                key={item.id}
                onClick={() => onSelectBatch(item)}
                className={classes.row}
              >
                <TableCell>{get(item, 'senderName', '')}</TableCell>
                <TableCell>{chainName}</TableCell>
                <TableCell className={classes.textCell}>
                  <Typography variant="body2" noWrap>
                    {body}
                  </Typography>
                </TableCell>
                <TableCell>{formatUtcDateTimeToLocal(item.sentTime)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={-1}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[rowsPerPage]}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={() => {
                const totalPages = get(paginationData, 'TotalPages', 1);
                if (totalPages > 0) {
                  return `Page ${page + 1} of ${get(
                    paginationData,
                    'TotalPages',
                    1
                  )}`;
                }
                return 'No messages';
              }}
              nextIconButtonProps={{
                disabled: !paginationData.HasNext || executingRequest,
              }}
              backIconButtonProps={{ disabled: page === 0 || executingRequest }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
