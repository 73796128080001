import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  APP_LOADING_START,
  APP_LOADING_SUCCESS,
  APP_LOADING_FAILURE,
  FETCH_MESSAGE_TYPES_SUCCESS,
} from './action-types';
import { snackBarVariant } from '../types/snackBar';

const initialState = {
  user: null,
  token: null,
  loading: {
    inProgress: false,
    failed: false,
  },
  snackbar: {
    open: false,
    message: null,
    variant: snackBarVariant.info,
  },
  messageTypes: [],
};

const rootReducer = function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGE_TYPES_SUCCESS:
      return {
        ...state,
        messageTypes: [...action.payload],
      };
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          variant: action.payload.variant,
          message: action.payload.message,
        },
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case APP_LOADING_START:
      return {
        ...state,
        loading: {
          failed: false,
          inProgress: true,
        },
      };
    case APP_LOADING_SUCCESS:
      return {
        ...state,
        loading: {
          failed: false,
          inProgress: false,
        },
      };
    case APP_LOADING_FAILURE:
      return {
        ...state,
        loading: {
          failed: true,
          inProgress: false,
        },
      };
    default:
      return state;
  }
};

export default rootReducer;
