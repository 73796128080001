import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '../contexts/auth0Context';
import FullPageLoading from './FullPageLoading';

export default function Login() {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

  if (isLoading) return <FullPageLoading />;

  if (isAuthenticated) return <Navigate to="/communication" replace={true} />;

  loginWithRedirect();
  return null;
}
