import { createSelector } from 'reselect';

export const getAuthentication = createSelector(
  (state) => state.authentication,
  (authentication) => authentication
);

export const getSnackbar = createSelector(
  (state) => state.snackbar,
  (snackbar) => snackbar
);

export const getLoading = createSelector(
  (state) => state.loading,
  (loading) => loading
);
