import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '../contexts/auth0Context';
import FullPageLoading from './FullPageLoading';

export const PrivateRoute = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) return <FullPageLoading />;
  if (!isAuthenticated) return <Navigate to="/login" />;
  return children;
};
