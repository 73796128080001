import { getEnvVarByName } from '../utils';

const carrierDactylRoot = getEnvVarByName('REACT_APP_CARRIER_DACTYL_API_ROOT');

const methods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

let getTokenSilently;
export const setGetTokenSilent = (m) => (getTokenSilently = m);

const fetchResponse = async (method, relativePath, body) => {
  if (!getTokenSilently) throw new Error('getTokenSilently not set');
  const token = await getTokenSilently();
  const url = `${carrierDactylRoot}/${relativePath}`;

  const requestObject = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  if (body) requestObject.body = body;
  return await fetch(url, requestObject);
};

const checkResponseBody = async (response) => {
  let responseBody = null;
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    responseBody = await response.json();
  }

  return responseBody;
};

const buildSuccessOrFailureObject = (relativePath, response, responseBody) => {
  if (response.status >= 200 && response.status < 400) {
    return buildSuccessObject(response, responseBody);
  } else {
    console.error(
      `Failure while calling ${relativePath}: ${response.status}`,
      responseBody
    );
    return buildFailureObject(response, responseBody);
  }
};

const buildSuccessObject = (response, responseBody) => {
  let pagination = null;
  for (let entry of response.headers.entries()) {
    if (entry[0] === 'x-pagination') pagination = JSON.parse(entry[1]);
  }
  const returnObject = {
    success: true,
    data: responseBody,
  };
  if (pagination) returnObject.pagination = pagination;
  return returnObject;
};

const buildFailureObject = (response, responseBody) => {
  let errorMessage;
  if (responseBody.message) errorMessage = responseBody.message;
  else {
    switch (response.status) {
      case 401:
      case 403:
        errorMessage = 'Not Authorized';
        break;
      case 404:
        errorMessage = 'Path Not Found';
        break;
      default:
        errorMessage = 'Please Refresh';
    }
  }
  return { success: false, status: response.status, errorMessage };
};

const doRequest = async (method, relativePath, body) => {
  try {
    const response = await fetchResponse(method, relativePath, body);
    const responseBody = await checkResponseBody(response);
    return buildSuccessOrFailureObject(relativePath, response, responseBody);
  } catch (err) {
    console.error(`Unexpected failure while calling ${relativePath}`, err);
    return { success: false, errorMessage: 'Please Refresh' };
  }
};

export const fetchChains = async (take, filter) => {
  return await doRequest(
    methods.GET,
    `v1/chain/list?take=${take}&filter=${filter}`
  );
};

export const fetchScheduledRequests = async (page = 0, pageSize = 50) => {
  return await doRequest(
    methods.GET,
    `v1/scheduledrequest/list?page=${page}&pageSize=${pageSize}&recipientTypes=Chain`
  );
};

export const fetchMessageBatches = async (pageSize, page = 0) => {
  let relativePath = `v1/message/batch/list?pageSize=${pageSize}&page=${page}&recipientTypes=Chain`;
  return await doRequest(methods.GET, relativePath);
};

export const fetchMessageTypes = async () => {
  return await doRequest(methods.GET, `messageType/list`);
};

export const fetchAddressDeliveryReport = async () => {
  return await doRequest(methods.GET, 'Provider/delivery-report');
};

export const fetchUnsubscribedAddressesReport = async () => {
  return await doRequest(methods.GET, 'Provider/unsubscribed-report');
};

export const syncMailGunUnsubscribed = async () => {
  return await doRequest(
    methods.POST,
    'Provider/sync-mailgun-unsubscribed',
    {}
  );
};

export const cancelScheduledRequest = async (sequenceNumber) => {
  return await doRequest(
    methods.DELETE,
    `v1/scheduledrequest/${sequenceNumber}`
  );
};

export const cancelMessageBatch = async (batchId) => {
  return await doRequest(methods.DELETE, `v1/message/batch/${batchId}`);
};

export const postMessageV1 = async ({
  messageType,
  recipientType,
  recipientIds,
  propertyBag,
  timeToSend,
}) => {
  const payload = {
    recipientType,
    recipientIds,
    messageTypeName: messageType,
    timeToSend,
    propertyBag,
  };
  return await doRequest(methods.POST, `v1/message`, JSON.stringify(payload));
};

export const messageTypes = {
  chainGeneral: 'ChainGeneral',
  general: 'General',
  openShiftV1: 'OpenShiftV1',
};

export const recipientTypes = {
  chain: 'Chain',
  organization: 'Organization',
  employee: 'Employee',
};
