import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  fetchAddressDeliveryReport,
  fetchUnsubscribedAddressesReport,
} from '../../services/carrierDactylService';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../store/actions';
import { snackBarVariant } from '../../types/snackBar';
import ContentHeader from '../Shared/ContentHeader';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    boxShadow: '0px 1px 3px #00000033',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '1.25px',
    position: 'relative',
    width: '328px',
    margin: '18px 0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 45px)',
      margin: '18px 22.5px',
    },
  },
}));

export default function AddressReportByType(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleGetAddressReport = async () => {
    setSubmitting(true);
    let reportResponse;
    if (props.serviceToFetch === 1) {
      reportResponse = await fetchAddressDeliveryReport();
    } else if (props.serviceToFetch === 2) {
      reportResponse = await fetchUnsubscribedAddressesReport();
    }
    if (reportResponse.success) {
      const csvString = jsonToCsv(reportResponse.data);
      downloadAsCsv(csvString);
    } else {
      dispatch(
        openSnackbar(
          `Failed to get Report. ${reportResponse.errorMessage}.`,
          snackBarVariant.error
        )
      );
    }
    setSubmitting(false);
  };

  const jsonToCsv = (array) => {
    if (!array || array.length === 0) return;

    const fieldList = Object.keys(array[0]);

    let csvString =
      fieldList
        .map((field) =>
          field
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
        )
        .join(',') + '\n';

    array.forEach(
      (row) =>
        (csvString += fieldList.map((field) => row[field]).join(',') + '\n')
    );
    return csvString;
  };

  const downloadAsCsv = (csvString) => {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
    hiddenElement.target = '_blank';
    hiddenElement.download = props.csvName;
    hiddenElement.click();
  };

  return (
    <React.Fragment>
      <ContentHeader title={props.title} subtitle={props.subtitle} />
      <Button
        color="primary"
        onClick={handleGetAddressReport}
        className={classes.submitButton}
        disabled={submitting}
        variant="contained"
      >
        Get Report
      </Button>
    </React.Fragment>
  );
}
