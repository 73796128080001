import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  APP_LOADING_START,
  APP_LOADING_SUCCESS,
  APP_LOADING_FAILURE,
  FETCH_MESSAGE_TYPES_SUCCESS,
} from './action-types';
import * as carrierDactylService from '../services/carrierDactylService';
import { snackBarVariant } from '../types/snackBar';

export const closeSnackbar = () => {
  return { type: CLOSE_SNACKBAR };
};

export const openSnackbar = (message, variant = snackBarVariant.info) => {
  return { type: OPEN_SNACKBAR, payload: { message, variant } };
};

export const login = (message, variant = snackBarVariant.info) => {
  return { type: OPEN_SNACKBAR, payload: { message, variant } };
};

export const loadApp = () => {
  return async (dispatch) => {
    dispatch({ type: APP_LOADING_START });

    try {
      await fetchFromCarrierDactylIntoState(
        dispatch,
        FETCH_MESSAGE_TYPES_SUCCESS,
        carrierDactylService.fetchMessageTypes
      );
    } catch (err) {
      console.error(err);
      return dispatch({ type: APP_LOADING_FAILURE });
    }

    return dispatch({ type: APP_LOADING_SUCCESS });
  };
};

const fetchFromCarrierDactylIntoState = async (
  dispatch,
  success_action,
  func
) => {
  const response = await func.call();
  if (response.success) {
    dispatch({
      type: success_action,
      payload: response.data,
    });
  } else {
    dispatch(
      openSnackbar(
        `Failed to load app. ${response.errorMessage}.`,
        snackBarVariant.error
      )
    );
    throw new Error(
      `Failed to load app with error: '${response.errorMessage}'`
    );
  }
  return dispatch({ type: APP_LOADING_SUCCESS });
};
